import { useState } from 'react'
import { RiEyeLine, RiEyeOffLine } from 'react-icons/ri'
import styles from './Input.module.scss'

const Input = (props) => {
  const inputClass =
    `${styles.input}` +
    (props.type ? ' ' + props.type : ' text') +
    (props.disabled ? ' disabled' : '')
  const [show, setShow] = useState(false)

  return (
    <>
      {props.type !== 'password' ? (
        <div className={inputClass}>
          <input
            // {...props}
            name={props.name}
            type={props.type}
            value={props.value}
            onChange={props.onChange}
            // onFocus={props.onFocus}
            // autoComplete={props.autoComplete}
            // autoFocus={props.autoFocus}
            // onBlur={props.onBlur}
            placeholder=" "
          />
          <label>{props.label}</label>
        </div>
      ) : (
        <div className={inputClass}>
          <input
            {...props}
            name={props.name}
            type={show ? 'text' : 'password'}
            value={props.value}
            onChange={props.onChange}
            onFocus={props.onFocus}
            autoComplete={props.autoComplete}
            onBlur={props.onBlur}
            placeholder=" "
          />
          <label>{props.label}</label>
          {show ? (
            <span onClick={() => setShow(false)}>
              <RiEyeLine />
            </span>
          ) : (
            <span onClick={() => setShow(true)}>
              <RiEyeOffLine />
            </span>
          )}
        </div>
      )}
    </>
  )
}

export default Input
