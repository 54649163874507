export const newGetCookie = (name) => {
  var nameEQ = name + '=';
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

function createObjectFromArray(arr) {
  const result = {};

  for (const item of arr) {
    const [key, value] = item.split('=');
    const updatedKey = key?.split('_')[2];
    result[updatedKey] = value;
  }

  return result;
}

export const getNewCookies = () => {
  var ca = document.cookie.split(';');
  var lastUtms = [];
  var originalUtms = [];
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') c = c.substring(1, c.length);
    if (!c?.includes('_session') && !c?.includes('gclid')) {
      if (c.includes('last_')) {
        lastUtms.push(c);
      }
      if (c.includes('original_')) {
        originalUtms.push(c);
      }
    }
  }
  const last_utm = createObjectFromArray(lastUtms);
  const original_utm = createObjectFromArray(originalUtms);
  return { last_utm, original_utm };
};
