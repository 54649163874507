import React, { useEffect, useState } from 'react';
import GoogleLogin from 'react-google-login';
import { useDispatch } from 'react-redux';
import { userGoogleSignIn, showAuthLoader, showAuthMessage } from '../../redux/actions/Auth';
// import queryString from 'query-string';
import { getNewCookies, newGetCookie } from '../../utils/getCookie';
import { googleClientId } from '../../utils/session';
const GoogleSignIn = ({ text }) => {
  const dispatch = useDispatch();

  // UTM HELPER FUNCTION
  // const utmHelper = (string) => {
  //   let utmStringify, utmParse;
  //   try {
  //     utmStringify = JSON.stringify(string).replaceAll('utm_', '');
  //     utmParse = JSON.parse(utmStringify);
  //   } catch (e) {
  //     null;
  //   }
  //   return utmParse;
  // };

  const responseGoogleSuccess = (response) => {
    let  sessionCookie, newUtms,gclidCookie;
    try {
      sessionCookie = JSON.parse(newGetCookie('session'));      
      gclidCookie = newGetCookie('original_gclid') ?? null;
      // const utmCookie = utmHelper(queryString.parse(newGetCookie('utm')));
       newUtms = getNewCookies();
    } catch (e) {
      null;
    }

    dispatch(showAuthLoader());
    dispatch(
      userGoogleSignIn({
        tokenId: response.tokenId,
        email: response.profileObj.email,
        session: sessionCookie,        
        gclid: gclidCookie,
        ...newUtms,        
      })
    );
  };

  const responseGoogleFailure = (response) => {
    dispatch(showAuthMessage(response.details));
  };

  const [loadingBtn, setLoadingBtn] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoadingBtn(false);
    }, 4000);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <div className="w-100">
      {googleClientId && (
        <GoogleLogin
          clientId={googleClientId}
          buttonText={loadingBtn ? 'loading...' : text}
          onSuccess={responseGoogleSuccess}
          disabled={loadingBtn}
          onFailure={responseGoogleFailure}
          className="google-SSO w-100 d-flex justify-content-center google-signin-register-login"
          cookiePolicy={'single_host_origin'}
        ></GoogleLogin>
      )}
    </div>
  );
};

export default GoogleSignIn;
